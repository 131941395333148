import React, { useEffect, useRef, useState } from 'react';
import FunoLogo from '../images/logo-white.svg';
import { logoAnimation } from '../animations/logo';
import { useLocation } from '@reach/router';
import { AnimationItem } from 'lottie-web';

type LogoProps = {
  width?: string | number;
  animated?: boolean;
};

const Logo = ({ width, animated }: LogoProps) => {
  const animRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const [anim, setAnim] = useState<AnimationItem | null>(null);

  useEffect(() => {
    let lottie: any = null;
    const dynamicLottie = async () => {
      lottie = await import('lottie-web');

      const params = {
        name: 'logo',
        container: animRef.current,
        loop: false,
        autoplay: false,
        animationData: logoAnimation,
      };

      const animation = lottie.loadAnimation(params);
      setAnim(() => animation);
      animation.playSegments([0, 60], true);
    };
    if (animated && animRef.current) {
      dynamicLottie();
    }

    return () => lottie?.destroy('logo');
  }, [animated]);

  // also replay on route change
  useEffect(() => {
    anim && anim.playSegments([0, 60], true);
  }, [location.pathname]);

  return (
    <div ref={animRef} style={{ width: width }}>
      {!animated && (
        <img className='link' src={FunoLogo} style={{ width: '100% ' }} alt='Funo Logo' />
      )}
    </div>
  );
};

export default Logo;
