import React, { createContext, useEffect } from 'react';

export const ScrollSpyContext = createContext<{
  activeElement: any;
  setActiveElement: (x: any) => void;
  ignore: boolean;
  setIgnore: (x: boolean) => void;
}>({} as any);

type ScrollSpyContextProviderProps = {
  children: React.ReactNode;
  activeElement: any;
  setActiveElement: any;
  ignore: boolean;
  setIgnore: (x: boolean) => void;
};

export const useActiveScrollspy = () => {
  const context = React.useContext(ScrollSpyContext);
  return {
    activeElement: context?.activeElement,
    setActiveElement: context?.setActiveElement,
    ignore: context?.ignore,
    setIgnore: context?.setIgnore,
  };
};

export default function ScrollSpyContextProvider({
  children,
  activeElement,
  setActiveElement,
  ignore,
  setIgnore,
}: ScrollSpyContextProviderProps) {
  return (
    <ScrollSpyContext.Provider value={{ activeElement, setActiveElement, ignore, setIgnore }}>
      {children}
    </ScrollSpyContext.Provider>
  );
}
