import { useLocation } from '@reach/router';
import React, { CSSProperties, useEffect } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Transition as ReactTransition, TransitionGroup } from 'react-transition-group';
import Navbar from './Navbar';
import ScrollSpyContextProvider from './ScrollSpyContext';

const timeout = 300;
const getTransitionStyles = {
  entering: {
    position: `absolute`,
    opacity: 0,
    transform: 'translateY(-100px)',
  },
  entered: {
    transition: `all ${timeout}ms ease-in-out`,
    opacity: 1,
    transform: 'translateY(0)',
  },
  exiting: {
    transition: `all ${timeout}ms ease-in-out`,
    opacity: 0,
    transform: 'translateY(100px)',
  },
};

type TransitionProps = {
  children: React.ReactNode;
};

function Transition({ children }: TransitionProps) {
  const location = useLocation();

  return (
    <TransitionGroup>
      <ReactTransition
        key={location.pathname}
        timeout={{
          enter: timeout,
          exit: timeout,
        }}
        onEntered={() => {
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        }}
      >
        {(status: any) => (
          <div
            style={{
              ...(getTransitionStyles[status as keyof typeof getTransitionStyles] as CSSProperties),
            }}
          >
            {children}
          </div>
        )}
      </ReactTransition>
    </TransitionGroup>
  );
}

function Layout({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    // smooth scrolling with lenis
    // const lenis = new Lenis();

    // function raf(time: any) {
    //   lenis.raf(time);
    //   requestAnimationFrame(raf);
    // }

    // requestAnimationFrame(raf);

    const navLinks = document.querySelectorAll('.nav-link');
    navLinks.forEach((link) => {
      link.classList.remove('active');
    });
    const scrollButtons = document.querySelectorAll('[data-scroll]');

    const handleClick = (e, button) => {
      const isHome = window.location.pathname === '/';
      isHome && e.preventDefault();

      const scrollTarget = (button as HTMLElement).dataset.scroll;

      document
        .getElementById(scrollTarget?.replace('#', '')!)
        ?.scrollIntoView({ behavior: 'smooth' });
    };

    scrollButtons.forEach((button) => {
      button.addEventListener('click', (e) => handleClick(e, button));
    });

    return () => {
      scrollButtons.forEach((button) => {
        button.removeEventListener('click', (e) => handleClick(e, button));
      });
    };
  }, []);

  const [activeElement, setActiveElement] = React.useState(null);
  const [ignore, setIgnore] = React.useState(false);

  return (
    <>
      {/* <Analytics /> */}
      <ScrollSpyContextProvider
        activeElement={activeElement}
        setActiveElement={setActiveElement}
        ignore={ignore}
        setIgnore={setIgnore}
      >
        <Navbar />
        <ParallaxProvider>
          <Transition>{children}</Transition>
        </ParallaxProvider>
      </ScrollSpyContextProvider>
    </>
  );
}

export default Layout;
