export const logoAnimation = {
  v: '5.10.1',
  fr: 29.9700012207031,
  ip: 0,
  op: 900.000036657751,
  w: 668,
  h: 599,
  nm: 'funoLogo',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Stroke',
      td: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [347, 295, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: { i: [[0, 0]], o: [[0, 0]], v: [[152.875, -141.5]], c: false },
                ix: 2,
              },
              nm: 'Pfad 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 0, ix: 1 },
              e: { a: 0, k: 100, ix: 2 },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Pfade trimmen 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 0.287745127958, 0.287745127958, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 36, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Kontur 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformieren',
            },
          ],
          nm: 'Form 8',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [18.25, -0.25],
                    [0.5, -0.875],
                    [0, -4.375],
                    [-22.5, -0.25],
                    [-0.875, 0],
                    [0, 4],
                  ],
                  o: [
                    [-18.25, 0.25],
                    [-0.5, 0.875],
                    [0, 4.375],
                    [22.5, 0.25],
                    [0.875, 0],
                    [0, -4],
                  ],
                  v: [
                    [183, -159.5],
                    [155.75, -139.75],
                    [157.375, 223.25],
                    [181.75, 246.5],
                    [209.75, 224.625],
                    [209.75, -137.75],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Pfad 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 0, ix: 1 },
              e: {
                a: 1,
                k: [
                  { i: { x: [0.105], y: [1] }, o: { x: [0.448], y: [0] }, t: 28, s: [0] },
                  { t: 59.0000024031193, s: [100] },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Pfade trimmen 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 0.287745127958, 0.287745127958, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 36, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Kontur 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformieren',
            },
          ],
          nm: 'Form 7',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-21.5, 0.25],
                    [0, -1.875],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [21.5, -0.25],
                    [0, 1.875],
                    [0, 0],
                  ],
                  v: [
                    [53.125, -140.625],
                    [80.25, -157.5],
                    [104.875, -139.5],
                    [105, 258.5],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Pfad 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 0, ix: 1 },
              e: {
                a: 1,
                k: [
                  { i: { x: [0], y: [1] }, o: { x: [0.333], y: [0] }, t: 30, s: [0] },
                  { t: 61.0000024845809, s: [100] },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Pfade trimmen 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 0.287745127958, 0.287745127958, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 36, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Kontur 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformieren',
            },
          ],
          nm: 'Form 6',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-0.5, -15],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.5, 15],
                    [0, 0],
                  ],
                  v: [
                    [29, -156.5],
                    [53, -137],
                    [53, 258.5],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Pfad 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 0, ix: 1 },
              e: {
                a: 1,
                k: [
                  { i: { x: [0], y: [1] }, o: { x: [0.333], y: [0] }, t: 29, s: [0] },
                  { t: 60.0000024438501, s: [100] },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Pfade trimmen 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 0.287745127958, 0.287745127958, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 36, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Kontur 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformieren',
            },
          ],
          nm: 'Form 5',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-0.125, 25.875],
                    [0, -3.5],
                  ],
                  o: [
                    [0, 0],
                    [0.126, -26.005],
                    [0, 0.5],
                  ],
                  v: [
                    [28, 245],
                    [2, 222],
                    [2, -170],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Pfad 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 0, ix: 1 },
              e: {
                a: 1,
                k: [
                  { i: { x: [0], y: [1] }, o: { x: [0.333], y: [0] }, t: 29, s: [0] },
                  { t: 60.0000024438501, s: [100] },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Pfade trimmen 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 0.287745127958, 0.287745127958, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 36, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Kontur 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 2], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformieren',
            },
          ],
          nm: 'Form 4',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-25.5, 1],
                    [0, 3],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [25.5, -1],
                    [0, -3],
                  ],
                  v: [
                    [-110.25, -158],
                    [-48, -158],
                    [-48, 220.5],
                    [-24.5, 247.5],
                    [1, 222],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Pfad 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 0, ix: 1 },
              e: {
                a: 1,
                k: [
                  { i: { x: [0], y: [1] }, o: { x: [0.333], y: [0] }, t: 11, s: [0] },
                  { t: 42.0000017106951, s: [100] },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Pfade trimmen 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 0.287745127958, 0.287745127958, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 36, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Kontur 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformieren',
            },
          ],
          nm: 'Form 3',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-113.75, -246.375],
                    [-113.925, -158.008],
                    [-114.75, 257.875],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Pfad 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 0, ix: 1 },
              e: {
                a: 1,
                k: [
                  { i: { x: [0], y: [1] }, o: { x: [0.333], y: [0] }, t: 6, s: [0] },
                  { t: 37.0000015070409, s: [100] },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Pfade trimmen 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 0.287745127958, 0.287745127958, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 36, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Kontur 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformieren',
            },
          ],
          nm: 'Form 2',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1, 0],
                    [65, -43],
                    [-49, -2],
                    [-42.675, 22.623],
                  ],
                  o: [
                    [0, 0],
                    [-1, 0],
                    [-65, 43],
                    [49, 2],
                    [41.5, -22],
                  ],
                  v: [
                    [19, -249],
                    [-131, -248],
                    [-280, -208],
                    [-245, -86],
                    [-129.5, -111.5],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Pfad 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 0, ix: 1 },
              e: {
                a: 1,
                k: [
                  { i: { x: [0], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [0] },
                  { t: 31.0000012626559, s: [100] },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Pfade trimmen 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 0.287745127958, 0.287745127958, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 66, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Kontur 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformieren',
            },
          ],
          nm: 'Form 1',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Main Logo Konturen',
      tt: 1,
      tp: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [347, 295, 0], ix: 2, l: 2 },
        a: { a: 0, k: [683, 384, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'n',
          pt: {
            a: 0,
            k: {
              i: [
                [0, 0],
                [0, 0],
                [0.045, -0.01],
                [0, 0],
                [-0.012, -0.044],
                [-0.056, 0.038],
                [0, 0],
              ],
              o: [
                [0, 0],
                [0, 0],
                [-0.047, 0.011],
                [0, 0],
                [0.012, 0.044],
                [0.056, -0.038],
                [0, 0],
              ],
              v: [
                [0.516, 0.177],
                [0.416, 0.176],
                [0.357, 0.185],
                [0.284, 0.247],
                [0.277, 0.324],
                [0.376, 0.375],
                [0.391, 0.364],
              ],
              c: false,
            },
            ix: 1,
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Maske 1',
        },
        {
          inv: false,
          mode: 'n',
          pt: { a: 0, k: { i: [[0, 0]], o: [[0, 0]], v: [[0.38, 0.242]], c: false }, ix: 1 },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Maske 2',
        },
      ],
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-221.259, 47.889],
                    [0, 0],
                    [-104.782, 80.318],
                    [74.175, 0],
                  ],
                  o: [
                    [0, 0],
                    [-237.836, 56.003],
                    [41.213, -31.594],
                    [-128.289, 0],
                  ],
                  v: [
                    [76.846, -114.025],
                    [89.865, -115.85],
                    [88.587, 35.532],
                    [73.796, 83.572],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Pfad 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.800000059838, 0.305882352941, 0.18431372549, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fläche 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [404.411, 242.951], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformieren',
            },
          ],
          nm: 'Gruppe 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.889, 1.903],
                    [2.834, 0],
                    [2.125, -1.903],
                    [0, -2.854],
                    [0, 0],
                    [-2.126, -1.903],
                    [-2.835, 0],
                    [-1.89, 1.902],
                    [0, 2.854],
                  ],
                  o: [
                    [0, -2.854],
                    [-1.89, -1.903],
                    [-2.835, 0],
                    [-2.126, 1.903],
                    [0, 0],
                    [0, 2.854],
                    [2.125, 1.902],
                    [2.834, 0],
                    [1.889, -1.903],
                    [0, 0],
                  ],
                  v: [
                    [289.853, -135.333],
                    [287.019, -142.468],
                    [279.934, -145.322],
                    [272.494, -142.468],
                    [269.306, -135.333],
                    [269.306, 223.563],
                    [272.494, 230.698],
                    [279.934, 233.552],
                    [287.019, 230.698],
                    [289.853, 223.563],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Pfad 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-10.864, 0],
                    [-8.031, -8.086],
                    [0, -11.416],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.889, 1.903],
                    [2.834, 0],
                    [2.126, -1.903],
                    [0, -2.854],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.89, 1.903],
                    [2.834, 0],
                    [0, 0],
                    [-7.558, -5.233],
                  ],
                  o: [
                    [11.336, 0],
                    [8.03, 8.087],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, -2.854],
                    [-1.889, -1.903],
                    [-2.834, 0],
                    [-2.125, 1.903],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, -2.854],
                    [-1.889, -1.903],
                    [0, 0],
                    [8.502, 0],
                    [7.558, -6.66],
                  ],
                  v: [
                    [176.488, -173.863],
                    [205.538, -161.733],
                    [217.583, -132.479],
                    [217.583, 259.952],
                    [186.407, 259.952],
                    [186.407, -135.333],
                    [183.573, -142.468],
                    [176.488, -145.322],
                    [169.048, -142.468],
                    [165.86, -135.333],
                    [165.86, 259.952],
                    [134.684, 259.952],
                    [134.684, -132.479],
                    [131.85, -139.614],
                    [124.765, -142.468],
                    [124.765, -171.722],
                    [148.855, -163.873],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Pfad 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 2,
              ty: 'sh',
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-119.66, 55.833],
                    [78.647, 0],
                    [-320.965, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.89, -1.903],
                    [-2.834, 0],
                    [-1.889, 1.902],
                    [0, 2.854],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.125, -2.14],
                    [-2.834, 0],
                    [0, 0],
                    [6.613, 5.232],
                    [9.919, 0],
                    [8.266, 8.086],
                    [0, 11.891],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-253.411, 8.414],
                    [49.598, -23.545],
                    [-136.038, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 2.854],
                    [1.889, 1.902],
                    [2.834, 0],
                    [1.89, -1.903],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 2.854],
                    [2.126, 2.141],
                    [0, 0],
                    [-9.447, 0],
                    [-8.503, 7.135],
                    [-11.337, 0],
                    [-8.267, -8.086],
                    [0, 0],
                  ],
                  v: [
                    [31.238, -145.077],
                    [-0.063, -145.077],
                    [-0.476, 259.238],
                    [-35.843, 259.238],
                    [-35.423, -235.916],
                    [-91.818, -121.42],
                    [-108.114, -56.49],
                    [-0.063, -262.806],
                    [115.074, -262.806],
                    [115.074, -236.049],
                    [-0.476, -236.049],
                    [-0.062, -171.722],
                    [63.122, -171.722],
                    [63.122, 223.563],
                    [65.957, 230.698],
                    [73.042, 233.552],
                    [80.127, 230.698],
                    [82.961, 223.563],
                    [82.961, -171.722],
                    [114.137, -171.722],
                    [114.137, 220.709],
                    [117.325, 228.2],
                    [124.765, 231.411],
                    [124.765, 259.952],
                    [100.675, 252.103],
                    [73.042, 262.806],
                    [43.638, 250.676],
                    [31.238, 220.709],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Pfad 3',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 3,
              ty: 'sh',
              ix: 4,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-11.337, 0],
                    [-8.03, -8.086],
                    [0, -11.416],
                    [0, 0],
                    [8.03, -8.086],
                    [11.336, 0],
                    [8.267, 8.086],
                    [0, 11.891],
                    [0, 0],
                    [-8.266, 8.087],
                  ],
                  o: [
                    [11.336, 0],
                    [8.03, 8.087],
                    [0, 0],
                    [0, 11.891],
                    [-8.03, 8.086],
                    [-11.337, 0],
                    [-8.266, -8.086],
                    [0, 0],
                    [0, -11.416],
                    [8.267, -8.086],
                  ],
                  v: [
                    [279.934, -173.863],
                    [308.983, -161.733],
                    [321.028, -132.479],
                    [321.028, 220.709],
                    [308.983, 250.676],
                    [279.934, 262.806],
                    [250.529, 250.676],
                    [238.13, 220.709],
                    [238.13, -132.479],
                    [250.529, -161.733],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Pfad 4',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Pfade zusammenführen 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fläche 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [586.321, 383.013], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformieren',
            },
          ],
          nm: 'Gruppe 2',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Dot Konturen',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 3, s: [0] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 28, s: [100] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 35, s: [80] },
            { t: 63.0000025660426, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0, y: 0.72 },
              o: { x: 0, y: 0 },
              t: 5,
              s: [343, -189, 0],
              to: [0, 64.88, 0],
              ti: [0, -80.667, 0],
            },
            {
              i: { x: 0.158, y: 0.78 },
              o: { x: 0.52, y: 0.995 },
              t: 34,
              s: [343, 295, 0],
              to: [0, 76.427, 0],
              ti: [0, 7.51, 0],
            },
            {
              i: { x: 0.66, y: 0.398 },
              o: { x: 0.327, y: 0.571 },
              t: 63,
              s: [343, 295, 0],
              to: [0, -0.417, 0],
              ti: [0, 0.004, 0],
            },
            { t: 64.0000026067734, s: [343, 295, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [683, 384, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [6.125, 0],
                    [0, 0],
                    [0, -6.126],
                    [0, 0],
                    [-6.124, 0],
                    [0, 0],
                    [0, 6.126],
                  ],
                  o: [
                    [0, -6.126],
                    [0, 0],
                    [-6.124, 0],
                    [0, 0],
                    [0, 6.126],
                    [0, 0],
                    [6.125, 0],
                    [0, 0],
                  ],
                  v: [
                    [22.191, -11.092],
                    [11.095, -22.193],
                    [-11.096, -22.193],
                    [-22.192, -11.092],
                    [-22.192, 11.092],
                    [-11.096, 22.193],
                    [11.095, 22.193],
                    [22.191, 11.092],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Pfad 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.800000059838, 0.305882352941, 0.18431372549, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fläche 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [956.657, 621.601], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformieren',
            },
          ],
          nm: 'Gruppe 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      ct: 1,
      bm: 0,
    },
  ],
  markers: [],
};
